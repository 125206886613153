@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

.right::before {
  content: '';
  position: absolute;
  right: -24px;
  width: 100px;
  height: 50px;
  background-image: linear-gradient(to right, transparent, white);
  z-index: 10;
}

.left::after {
  content: '';
  position: absolute;
  left: -24px;
  width: 100px;
  height: 50px;
  background-image: linear-gradient(to left, transparent, white);
  z-index: 10;
}
